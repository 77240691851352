var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"inferred-container\">\n  <h2>\n    "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"parser") || (depth0 != null ? lookupProperty(depth0,"parser") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"parser","hash":{},"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":14}}}) : helper)))
    + "\n  </h2>\n  "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"rendered") || (depth0 != null ? lookupProperty(depth0,"rendered") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"rendered","hash":{},"data":data,"loc":{"start":{"line":5,"column":2},"end":{"line":5,"column":16}}}) : helper))) != null ? stack1 : "")
    + "\n</div>\n";
},"useData":true});